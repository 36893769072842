import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"

export default () => 
<div>
    <SEO title={'Nearshore Development Team in Sofia | ICON Worldwide'} 
    description="Looking for an outsourcing solution for your IT projects? If so, discover ICON's nearshore development team in Sofia!"
    canonical={'https://icon-worldwide.com/blog/sofia-nearshore-development-team'} />

    <NewsHeader/>

    <div id="single-news">
        <h1>OUR <span>SOFIA</span> TEAM</h1>
        <div id="title-underline"></div>
        <p>An inspiring week with the ICON Sofia team! I really encourage all companies considering out/near-shore development scenarios to get on the plane and be sure you know the people writing your code. Like anything else in business, everything depends on the talent and personalities you work with!</p>
        <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/software-development-outsourcing/">Software Development Outsourcing Companies</a></p>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

        <NewsFooter previous="security-checklist-for-developers" next="successful-personalised-nearshore-development"/>
    </div>

    <Footer noScrollbar="true"/>
</div>